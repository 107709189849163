* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background: #f7f7f7;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  min-height: 100vh;
  font-family: "Poppins", sans-serif;
}

.container {
  margin: 30px auto;
  width: auto;
}

.title {
  color: #393053;
  text-align: center;
}

.link-container {
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 600;
  padding: 15px 0;
}

.link-container > div > a {
  text-decoration: none;
  color: #3a1078;
  padding: 0 20px;
}

.link-container > div > a:hover {
  color: #3795bd;
}
